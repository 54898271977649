.orders-table-root-container {
  .MuiTableCell-head {
    // background-color:#1C1C1C !important;
    background-color: #1c1c1c !important;
    border-bottom: 1px solid gray;
  }
  .orders-table-root-admin-methods {
    display: flex;
    margin: 1rem auto;
    align-items: center;
    justify-content: space-around;
    .single-orders-table-option-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 16%;
    }
  }
  //   max-height: 500px;
  //   overflow-y: auto;
  // border: 2px solid grey !important;
  // border-radius: 4px !important;
  ::-webkit-scrollbar {
    display: none;
  }
}
