.security-trading-page-root-container-parent-root {
  background: #1c1c1c;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 120vh;
  overflow-y: auto;
}

.security-trading-page-root-container-parent {
  width: 100%;
  background: #1c1c1c;
  position: relative;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.security-trading-page-root-container {
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #07232e;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid rgb(83, 83, 83);

  .security-trading-page-container-header {
    text-align: left;
    color: white;
    font-size: 14;
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .security-trading-page-security-trading-info-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 420px;
    @media only screen and (max-width: 668px) {
      min-width: 300px;
    }
    .security-password-container {
      .security-password-container {
        color: white;
        font-size: 1.5rem;
      }
      p {
        // font-family: "Roboto", sans-serif !important;
        font-weight: 400;
        line-height: 1.5;
        word-wrap: break-word;
        text-align: center;
        box-sizing: border-box;
        color: #69ab00;
        font-size: 22px;
      }
    }
    .MuiButton-root {
      &:active {
        background: #18aa7c;
        color: "#fff";
      }
      &:hover {
        background: #18aa7c;
        color: "#fff";
      }
    }
  }
  .MuiInput-root {
    color: black;
    margin-bottom: 20px !important;
    &:focus {
      outline: none;
      border: none;
      border-bottom: 0.1rem solid #08b786;
    }

    &:after {
      border-bottom: 2px solid #08b786;
    }
  }
}

@media only screen and (max-width: 668px) {
}
