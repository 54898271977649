.ordersWrap {
  background-color: #1c1c1c !important;
  .orderGroup {
    display: flex;
    justify-content: center;
    .orderButtons {
      font-size: 12px;
      margin: 4px;
    }
  }

  .orders {
    // color: white;
    box-shadow: -1px 1px 17px 2px;
    margin: 1rem;
    // background-color: rgb(126, 126, 126);
    // border: 2px solid grey !important;
    // border-radius: 4px !important;
    .order {
      display: flex;
      justify-content: space-between;
      margin: 10px;
    }
    .closeOrderButton {
      border: none;
      padding: 4px;
      color: white;
      background-color: rgb(73, 73, 73);
    }
  }
  .close-order-button {
    border: none;
    padding: 4px;
    color: white;
    background-color: rgb(73, 73, 73);
  }
}
