.trade-page-container {
  background: #1c1c1c;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-y: auto;
  .trade-page-subcontainer {
    margin-top: 5rem;
  }
}

.trade-page-sub-navbar-container {
  position: relative;
  z-index: 9999;
  width: 100%;
  height: 50px;
  background: #1c1c1c;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-left: none;
  border-right: none;
  margin-top: 5rem;
}

.trade-page-sub-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
  margin: 4px auto;
  list-style-type: none;
  @media only screen and (max-width: 1250px) {
    width: 100%;
  }
}

li {
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
a {
  transition: none;
}

.trading-widgets-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  height: 50%;
  margin: 0 auto;
  background-color: #1c1c1c;
  padding-top: 1rem;
  //padding-bottom: 50px;
  border-radius: 5px;
  //border: 1px solid #535353;
  z-index: 9999;
  position: relative;
  padding-bottom: 5rem;
}

.trading-wrap {
  display: flex;
  flex-direction: column;
  // margin-right: 1%;
  width: 60%;

  .widgets-market {
    display: flex;
    width: 100%;
  }
}

@media only screen and (max-width: 667px) {
  .trading-wrap {
    width: 100%;
  }
  .widgets-market {
    flex-direction: column;
  }
  .trading-widgets-container {
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }
  .crypto-container {
    width: 98%;
    align-self: center;
    margin-top: 5%;
  }
  .trade-market-container {
    // width: 100%;
    width: 90%;
    padding: 1rem;
    margin: 10% auto;
    text-align: start;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1300px) {
  .trade-market-container {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .react-form {
      width: 95%;
    }
  }
  .widgets-market {
    // width: 90%;
  }
}
